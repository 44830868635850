<template>
    <CRow>
        <CCol sm="12">
            <ListForm :headerTitle="$t('common.orders')" :onSearch="onSearch">
                <template v-slot:searchBox>
                    <CForm>
                        <CRow>
                            <CCol md="6">
                                <CInput :label="$t('pages.orders.orderNumber')" :placeholder="$t('pages.orders.orderNumber')"
                                        v-model="searchInfo.orderNumber" required horizontal>
                                </CInput>
                            </CCol>
                            <CCol md="6">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.agent')}} </label>
                                    <div class="col-sm-9">
                                        <multiselect :options="agents"
                                                     v-model="searchInfo.selectedAgents"
                                                     :loading="isLoadingAgents"
                                                     :searchable="true"
                                                     :close-on-select="true"
                                                     :clear-on-select="false"
                                                     :multiple="true"
                                                     :placeholder="$t('common.typetosearch')"
                                                     :noOptionsText="$t('common.theListIsEmpty')"
                                                     :reset-after="false"
                                                     :local-search="false"
                                                     @search-change="asyncFind"
                                                     id="ajax"
                                                     label="name"
                                                     track-by="id">
                                        </multiselect>
                                    </div>
                                </div>

                            </CCol>
                        </CRow>

                        <CRow>
                            <CCol md="6">

                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.startDate')}} </label>
                                    <div class="col-sm-9">
                                        <date-picker v-model="searchInfo.startDate" format="DD/MM/YYYY"></date-picker>
                                    </div>
                                </div>

                               
                            </CCol>

                            <CCol md="6">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.status')}} </label>
                                    <div class="col-sm-9">
                                        <multiselect :options="allOrderStatus"
                                                     v-model="searchInfo.selectedStatusList"
                                                     :searchable="false"
                                                     :close-on-select="false"
                                                     :clear-on-select="false"
                                                     :limit="10"
                                                     :multiple="true"
                                                     placeholder="Pick some"
                                                     :reset-after="false"
                                                     label="label"
                                                     track-by="key">
                                        </multiselect>
                                    </div>
                                </div>


                            </CCol>

                        </CRow>

                        <CRow>
                            <CCol md="6">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.endDate')}} </label>
                                    <div class="col-sm-9">
                                        <date-picker v-model="searchInfo.endDate" format="DD/MM/YYYY"></date-picker>
                                    </div>
                                </div>
                            </CCol>
                        </CRow>
                    </CForm>


                </template>
                <template v-slot:list>
                    <CDataTable :items="orders"
                                :fields="fields"
                                :noItemsView="{ noItems: $t('common.nodatafound') }"
                                hover
                                striped
                                border
                                small
                                fixed
                                @page-change="onPageChanged"
                                :loading="isLoading"
                                :items-per-page="itemsPerPage">

                        <template #agent="{item}">
                            <td class="py-2">
                                <h5>{{item.agent.name}}</h5>
                                <span class="font-italic">{{item.agent.address}}</span>
                            </td>
                        </template>

                        <template #shippingAddress="{item}">
                            <td class="py-2">
                                <h5>{{item.shippingAddress.fullName}}</h5>
                                <span class="font-italic">{{item.shippingAddress.streetAddress}}</span><br />
                                <span>ĐT: {{item.shippingAddress.phoneNumber}}</span>
                            </td>
                        </template>
                        <template #totalPrice="{item}">
                            <td class="py-2">
                                <label v-text="$func.formatCurrency(item.totalPrice)" />
                            </td>
                        </template>
                        <template #createdDate="{item}">
                            <td class="py-2">
                                <label v-text="formatDate(item.createdDate)" />
                            </td>
                        </template>
                        <template #status="{item}">
                            <td class="py-2">
                                <button type="button" class="btn" :class="statusButtonCss(item.status)">{{$t(`pages.orders.orderStatus_${item.status}`)}}</button>

                            </td>
                        </template>
                        <template #orders_detail_actions="{item}">
                            <td class="py-2">
                                <CButton color="primary"
                                         variant="outline"
                                         square
                                         size="sm"
                                         @click="onViewOrderDetail(item)">
                                    {{ $t('pages.orders.detail') }}
                                </CButton>
                            </td>
                        </template>
                    </CDataTable>

                    <CPagination :pages="totalPages"
                                 :activePage="pageIndex + 1"
                                 @update:activePage="onPageChanged"></CPagination>
                </template>
            </ListForm>
            <Confirmation ref="confirmation"></Confirmation>
        </CCol>
    </CRow>

</template>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex'
    import i18n from '@/plugins/i18n'
    import Confirmation from '@/components/Confirmation.vue'
    import ListForm from '@/components/ListForm.vue'
    import OrderStatus from '@/helpers/OrderStatus'

    export default {
        name: 'OrdersList',
        data() {
            return {
                agents: [],
                isLoadingAgents: false,
                allOrderStatus: [],
                itemsPerPage: 2,
                warningModal: false,
                fields: [
                    { key: 'orderNumber', label: i18n.t('pages.orders.orderNumber') },
                    {
                        key: 'agent',
                        label: i18n.t('common.agent')
                    },
                    { key: 'shippingAddress', label: i18n.t('common.shippingAddress') },
                    { key: 'totalPrice', label: i18n.t('common.totalPrice') },
                    { key: 'createdDate', label: i18n.t('common.createdDate') },
                    { key: 'status', label: i18n.t('common.status') },
                    {
                        key: 'orders_detail_actions',
                        label: i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
                ]
            };
        },
        components: { Confirmation, ListForm },
        computed: {
            ...mapGetters('auth', ['user']),
            ...mapState('orders', ['searchInfo', 'orders', 'isLoading', 'totalPages', 'pageIndex']),
        },
        methods: {
            ...mapActions('orders', ['searchOrders']),
            formatDate(date) {
                if (date === undefined)
                    return '';
                return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
            },            
            onViewOrderDetail(item) {

                this.$router.push('/orders/detail/' + item.id);
            },

            onSearch() {
                this.searchOrders();
            },
            onPageChanged(pageNo, reduced) {
                console.log('PageChanged', { pageNo, reduced });
                this.searchOrders(pageNo);
            },


            async asyncFind(query, id) {
                console.log("LENGTH", query.length);
                if (query.length >= 3) {
                    this.isLoadingAgents = true;
                    var result = await this.$agentService.searchByName(query);
                    this.isLoadingAgents = false;
                    this.agents = result;
                }
                console.log("Search", { query, id });
            },
            
            statusButtonCss(status) {
                if (status == OrderStatus.Pending)
                    return "btn-danger";
                if (status == OrderStatus.Approved)
                    return "btn-primary";
                if (status == OrderStatus.Shipped)
                    return "btn-info";
                if (status == OrderStatus.Delivered)
                    return "btn-success";
                if (status == OrderStatus.Canceled)
                    return "btn-warning";
                if (status == OrderStatus.Returned)
                    return "btn-dark";
            }
        },
        created() {
            this.allOrderStatus = this.$ordersService.getAllStatusList();
            this.searchOrders();
            
        }
    }
</script>